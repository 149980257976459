import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import "../styles/pages/form.css"

const FormPostTemplate = ({ helmet, form_script }) => {
  return (
    <section id="form">
      {helmet || ""}
      <div id="form-page" className="container">
        {/* <h1>{heading.text}</h1> */}
        <span dangerouslySetInnerHTML={{ __html: form_script.text }} />
      </div>
    </section>
  )
}

const Form = (props) => {
  // Define the Post content returned from Prismic
  const doc = props?.data?.prismicForm
  let data
  if (doc) {
    data = doc.data
  }

  if (!doc || !data) return null
  return (
    <Layout location={props.location} page="service">
      <div className="form-page">
        <FormPostTemplate
          {...data}
          helmet={
            <Seo
              location={props.location}
              title={`${data.heading.text} Form | RateLock`}
              description={data.heading.text}
              // image={data.service_image.fluid.src}
            ></Seo>
          }
        />
      </div>
    </Layout>
  )
}

export const formQuery = graphql`
  query From($uid: String) {
    prismicForm(uid: { eq: $uid }) {
      uid
      url
      data {
        heading {
          text
        }
        form_script {
          text
        }
      }
    }
  }
`
export default withPreview(Form)
